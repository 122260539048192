 #container_vid {
     position: fixed;
     transition: transform .5s ease-in;
     transform: translateY(1000%);
     will-change: transform;
     top: 122px;
     left: 8px;
     z-index: 9999;
}

#vid {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
#vid-text {
    margin: auto !important;
}
